import React, { useEffect } from "react";
import { useAuth } from '../Context/AuthContext';
import { useNavigate } from "react-router-dom";
const AzureCallbackMock = () => {
    const navigate=useNavigate();
    const { isAuthenticated, Auth, notAuth } = useAuth();

    // localStorage.setItem('auth',false);
  useEffect(() => {
    Auth();  
    setTimeout(() => {
        navigate('/')
    }, 4000); 
  }, []);
 
  
  return (
    <div>
      <p>User is Authenticated 1st time ...</p>
    </div>
  );
};

export default AzureCallbackMock;
