import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IndexedDBHandler from "../DB/db";
import { dblClick } from "@testing-library/user-event/dist/click";
import { useNavigate } from "react-router-dom";
import { useTabContext } from "../Context/TabContext";
import { AddFormDataToMypcr } from "../DB/db2";

const CreateEPCRPage = () => {
  const { active, Tabon, setActiveTabbar } = useTabContext();

  const [isOnline, setIsOnline] = useState(true);
  const [activeTab, setActiveTab] = useState(3); // Set the initial active tab index
  useEffect(() => {
    // Function to update online status
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      console.log("isonline", isOnline);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);
  const handleValue = () => {
    setActiveTabbar(3);
  };
  const [TransferNumber, setTransferNumber] = useState("");
  const [Submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({});
  const [VehicleNumber, setVehicleNumber] = useState("");
  const [VehicleType, setVehicleType] = useState("");
  const [Role, setRole] = useState("");
  const [Practitioner, setPractitioner] = useState("");
  const [PartnerId, setPartnerid] = useState("");
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showErrorMessage2, setShowErrorMessage2] = useState(false);
  const [showErrorMessage3, setShowErrorMessage3] = useState(false);
  const [showErrorMessage4, setShowErrorMessage4] = useState(false);
  const [showErrorMessage5, setShowErrorMessage5] = useState(false);
  const [showErrorMessage6, setShowErrorMessage6] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const handleRadioClick = (e) => {
    setIsChecked(!isChecked);
    setPractitioner(true);
  };
  const handleRadioClick2 = (e) => {
    console.log("event", e.id);
    setShowErrorMessage5(e.target.value === "");

    setSelectedOption(e.target.value);
    setPractitioner(e.target.value);
    setIsChecked2(!isChecked2); // Toggle the isChecked state on each click
  };
  const handleFunc = (save) => {
    console.log("func called", save);
    if (redirecting) {
      AddFormDataToMypcr(save);
    }
  };
  const handleModalShow = () => {
    console.log("showModal", showModal);
    setShowModal(true);
    formSubmit();
  };
  const [redirecting, setRedirecting] = useState(false);

  // Add a useEffect hook to handle the redirect after the form is submitted
  useEffect(() => {
    if (Submitted) {
      setRedirecting(true);

      // Simulate the redirect after 2 seconds
      const redirectTimer = setTimeout(() => {
        navigate("/dashboard"); // Redirect to the desired page
      }, 2000);

      // Clean up the timer when the component is unmounted
      return () => clearTimeout(redirectTimer);
    }
  }, [Submitted]);
  const formSubmit = () => {
    console.log("form Submit");
    setFormData({
      TransferNumber,
      VehicleNumber,
      VehicleType,
      Role,
      PartnerId,
      Practitioner,
    });
    Tabon();
    if (PartnerId === "") {
      setShowErrorMessage6(true);
      setSubmitted(false);
    }
    if (selectedOption === "") {
      setShowErrorMessage5(true);
      setSubmitted(false);
    }
    if (VehicleType === "") {
      setShowErrorMessage4(true);
      setSubmitted(false);
    }
    if (Role === "") {
      setShowErrorMessage3(true);
      setSubmitted(false);
    }
    if (TransferNumber === "") {
      setShowErrorMessage2(true);
      setSubmitted(false);
    }

    if (VehicleNumber === "") {
      setShowErrorMessage(true);
      setSubmitted(false);
    } else {
      setSubmitted(true);
    }

    // navigate("/dashboard");
  };

  const save = formData;
  handleFunc(save);
  console.log("save", save);
  console.log("LLLL", formData);
  return (
    <>
      <div>
        <div
          className="slds-grid slds-grid_vertical-align-center slds-align_absolute-center"
          style={{ height: "100vh" }}
        >
          <div
            className="slds-box slds-theme_default slds-p-around_medium"
            style={{
              maxWidth: "500px",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <h2
              className="slds-text-heading_medium slds-m-bottom_medium"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                color: "gray",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Create new PCR
            </h2>
            <h2
              className="slds-text-heading_medium slds-m-bottom_medium"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                color: "gray",
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              Inorder to get new PCR please provide the following
            </h2>
            <form>
              <div
                className="slds-grid slds-grid_align-spread slds-m-bottom_medium"
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <label
                  className="slds-form-element__label"
                  htmlFor="usernumber"
                  style={{ fontSize: "15px" }}
                >
                  Transfer Number:
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="number"
                    required
                    id="usernamenumber"
                    className="slds-input"
                    onChange={(e) => {
                      setTransferNumber(e.target.value);
                      setShowErrorMessage2(e.target.value === ""); // Set isEmpty to true if the input is empty.
                    }}
                  />
                  {showErrorMessage2 && (
                    <div style={{ color: "red" }}>Please fill the field.</div>
                  )}
                </div>
              </div>
              <div
                className="slds-grid slds-grid_align-spread slds-m-bottom_medium"
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <label
                  className="slds-form-element__label"
                  htmlFor="password"
                  style={{ fontSize: "15px" }}
                >
                  Vehicle Number:
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="number"
                    required
                    title="Field is required"
                    id="numbervehicle"
                    className="slds-input"
                    onChange={(e) => {
                      setVehicleNumber(e.target.value);
                      setShowErrorMessage(e.target.value === ""); // Set isEmpty to true if the input is empty.
                    }}
                  />
                  {showErrorMessage && (
                    <div style={{ color: "red" }}>Please fill the field.</div>
                  )}
                </div>
              </div>
              <div
                className="slds-grid slds-grid_align-spread slds-m-bottom_medium"
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <label
                  class="slds-form-element__label "
                  for="select-01"
                  style={{ fontSize: "15px" }}
                >
                  Vehicle Type:
                </label>
                <div class="slds-form-element">
                  <div
                    class="slds-form-element__control slds-grid"
                    style={{ flexDirection: "column" }}
                  >
                    <div class="slds-select_container">
                      <select
                        class="slds-select"
                        id="select-02"
                        required
                        onChange={(e) => {
                          setVehicleType(e.target.value);
                          setShowErrorMessage4(e.target.value === ""); // Set isEmpty to true if the input is empty.
                        }}
                      >
                        <option>Select…</option>
                        <option value="option1">Option One</option>
                        <option value="option2">Option Two</option>
                        <option value="option3">Option Three</option>
                      </select>
                    </div>
                    {showErrorMessage4 && (
                      <div style={{ color: "red" }}>Please Select one </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="slds-grid slds-grid_align-spread slds-m-bottom_medium"
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <label
                  className="slds-form-element__label"
                  htmlFor="phone"
                  style={{ fontSize: "15px" }}
                >
                  Your Role:
                </label>
                <div class="slds-form-element">
                  <div
                    class="slds-form-element__control slds-grid "
                    style={{ flexDirection: "column" }}
                  >
                    <div class="slds-select_container">
                      <select
                        class="slds-select"
                        id="select-01"
                        required
                        onChange={(e) => {
                          setRole(e.target.value);
                          setShowErrorMessage3(e.target.value === ""); // Set isEmpty to true if the input is empty.
                        }}
                      >
                        <option>Select…</option>
                        <option value="option1">Option One</option>
                        <option value="option2">Option Two</option>
                        <option value="option3">Option Three</option>
                      </select>
                    </div>
                    {showErrorMessage3 && (
                      <div style={{ color: "red" }}>Please Select one </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="slds-grid  slds-m-bottom_medium"
                style={{ gap: "76px" }}
              >
                <label
                  className="slds-form-element__label"
                  htmlFor="address"
                  style={{ fontSize: "15px" }}
                >
                  Attended Practioner
                </label>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="slds-form-element__control"
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    <input
                      type="radio"
                      name="options"
                      id="checkbox-unique-id-81"
                      value="Yes"
                      checked={selectedOption === "Yes"}
                      onChange={handleRadioClick2}
                    />
                    <label
                      class="slds-checkbox__label"
                      for="checkbox-unique-id-81"
                      style={{ paddingLeft: "12px" }}
                    >
                      <span class="slds-checkbox_faux"></span>
                      <span class="slds-form-element__label">Yes</span>
                    </label>
                    <input
                      type="radio"
                      name="options"
                      id="checkbox-unique-id-80"
                      value="No"
                      checked={selectedOption === "No"}
                      onChange={handleRadioClick2}
                    />
                    <label
                      class="slds-checkbox__label"
                      for="checkbox-unique-id-80"
                      style={{ paddingLeft: "12px" }}
                    >
                      <span class="slds-checkbox_faux"></span>
                      <span class="slds-form-element__label">No</span>
                    </label>
                  </div>

                  {showErrorMessage5 && (
                    <div style={{ color: "red" }}>Please select one option</div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="slds-grid slds-grid_align-spread  slds-m-bottom_medium"
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
                >
                  <label
                    className="slds-form-element__label"
                    htmlFor="address"
                    style={{ fontSize: "15px" }}
                  >
                    ID#
                  </label>
                  <div
                    className="slds-form-element__control"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    <input
                      type="number"
                      id="address"
                      className="slds-input"
                      placeholder="Id#"
                      onChange={(e) => {
                        setPartnerid(e.target.value);
                        setShowErrorMessage6(e.target.value === ""); // Set isEmpty to true if the input is empty.
                      }}
                    />

                    <input
                      type="radio"
                      name="default"
                      id="radio-61"
                      value="radio-61"
                      checked={isChecked}
                      onChange={handleRadioClick} // Set to true if the radio button should be checked by default
                    />
                    <label
                      className="slds-checkbox__label"
                      htmlFor="checkbox-unique-id-81" // Change the 'for' attribute to 'htmlFor'
                      style={{ paddingLeft: "2px", paddingTop: "2px" }}
                    >
                      <span className="slds-checkbox_faux"></span>
                      <span
                        className="slds-form-element__label"
                        style={{ width: "100px" }}
                      >
                        notapplicable
                      </span>
                    </label>
                  </div>
                </div>
                {showErrorMessage6 && (
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-12px",
                      marginLeft: "140px",
                    }}
                  >
                    Please fill one of them
                  </div>
                )}
              </div>

              <div
                className="slds-m-top_medium"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="slds-button slds-button_brand"
                  style={{ textDecoration: "none", color: "gray" }}
                  onClick={handleModalShow}
                >
                  Confirm
                </div>
                <Link to="/dashboard">
                  <button
                    onClick={handleValue}
                    className="slds-button slds-button_brand"
                    type="submit"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEPCRPage;
