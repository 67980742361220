import React, { useEffect } from "react";

const SaleForceCallbackMock = () => {
  useEffect(() => {

    setTimeout(() => {

      window.location.href = "/Dashboard"; 
    }, 2000);
  }, []);

  return (
    <div>
      <p>User is Authenticated 2 time...</p>
    </div>
  );
};

export default SaleForceCallbackMock;
