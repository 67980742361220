import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header"; // Assuming you have a Header component
import Footer from "./components/Footer"; // Assuming you have a Footer component
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import CreateEPCRPage from "./pages/CreateEPCRPage";
import MyPCRsPage from "./pages/MyPCRsPage";
import OAuthCallback from "./pages/CallbackPage"; // Assuming CallbackPage.js is the correct file
import CallbackPage from "./pages/CallbackPage";
import "@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.css";
import { DataProvider } from "./Context/DataContext";
import { register } from "./serviceRegistration";
import FakeAzure from "../src/pages/FakeAzure";
import FakeSaleForceAuth from "../src/pages/FakeSaleForceAuth";
import SaleForceCallbackMock from "../src/pages/FakeSaleForceAuth";
import InstallPWA from "./pages/InstallPage";
import IndexedDBInitializer from "./pages/Extra";
import { AuthProvider } from "../src/Context/AuthContext";
import { DeferredPromptProvider } from "../src/Context/PromptContext";
import { TabProvider } from "./Context/TabContext";
function App() {
  useEffect(() => {
    register();
  }, []);
  // useEffect(() => {
  //   // Inside the useEffect, call the register function with the onSuccess callback.
  //   register({
  //     onSuccess: (registration) => {
  //       // Handle successful registration
  //       console.log('Service worker registration successful', registration);
  //       // You can also perform additional actions here, such as showing a success message to the user.
  //     },
  //   });
  // }, []);

  return (
    <>
      <Router>
        <AuthProvider>
          <DeferredPromptProvider>
            <DataProvider>
              <TabProvider>
              <Routes>
                <Route path="/" element={<InstallPWA />} />

                <Route path="/azcallback" element={<FakeAzure />} />
                {/* <Route path='/abc' element={<IndexedDBInitializer/>}/> */}

                <Route
                  path="/salesforcecallback"
                  element={<SaleForceCallbackMock />}
                />
                <Route path="/Login" element={<LoginPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/createecpr" element={<CreateEPCRPage />} />
                <Route path="/viewcpr" element={<MyPCRsPage />} />
                {/* <Route path="/oauth-callback" element={<OAuthCallback />} />{" "} */}
                {/* Assuming you have OAuthCallback defined */}
                {/* <Route path="/callback" element={<CallbackPage />} />{" "} */}
                {/* Correct name */}
              </Routes>
              </TabProvider>
            </DataProvider>
          </DeferredPromptProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
