import React from "react";
import { useEffect } from "react";
import { PiFirstAidKitFill } from "react-icons/pi";
import { AiFillCar } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { MdContacts } from "react-icons/md";
import { useState } from "react";
import { useDataContext } from "../Context/DataContext";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdRefresh } from "react-icons/io";
import { RetrieveFormDataFromMypcr } from "../DB/db2";
const MyPCRsPage = () => {
  const [impdata, setimpData] = useState([]);
  const [select, setSelect] = useState("");

  const {setData, data} = useDataContext();
  // console.log('SET',data)

  // if (data.length === 0) {
  //   console.log(data.length);
  //   return <div></div>;
  // }
const saved={data};
console.log('saved',saved)
// console.log('present',{data});
// const obj = JSON.parse(data); 
// console.log('saved',obj);
// const dataArray = obj.data;

// dataArray?.forEach(item => {
//   // Access and process each item in the array
//   console.log("kk",item);
// });

  useEffect(() => {
    console.log('use effct')
    RetrieveFormDataFromMypcr()
      .then((data) => {
        // Now you can use the 'data' variable to work with the retrieved data
        console.log('length', data.length);
        if (data && Array.isArray(data) && data.length > 0) {
          // Check if data exists, is an array, and has elements
          const jsonData = JSON.stringify({ data });
          setData(jsonData);
          console.log("Received data:", data);
          // You can update your component's state or perform other operations with 'data'.
        } else {
          console.log("No valid data received.");
          // Handle the case when no valid data is received
        }
        // You can update your component's state or perform other operations with 'data'.
      })
      .catch((error) => {
        console.error("Error while fetching data:", error);
      });
  }, [data]);
  console.log("data", data);
  const transferNumbers = impdata?.map((item) => item.TransferNumber);
  console.log(transferNumbers, "Transfer");

  var pcrData=data

// const pcrdata= pcrData !== null && pcrData !== undefined ? JSON?.parse(pcrData) : null;
const pcrdata = pcrData?.length > 0 ? JSON.parse(pcrData) : null;


  // const pcrdata=JSON?.parse(pcrData)




 console.log(pcrdata, "data is pcr");


  const selectedData = pcrdata?.data?.find((pcr) => pcr.TransferNumber === select);
  console.log("new", pcrdata);
  console.log("selecteding", selectedData?.TransferNumber);
  console.log("set",impdata);

  return (
    <div
      style={{
        backgroundColor: "white",
        marginTop: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          border: "1px solid gray",
          marginTop: "20px",
          flexDirection: "column",
          gap: "10px",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          {" "}
          <span
            style={{
              color: "#51c386",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdContacts />
          </span>
          <span style={{ fontWeight: "700" }}>PCR</span>{" "}
        </div>

        <div
          style={{ display: "flex", flexDirection: "column", color: "grey" }}
        >
          {/* <span>3 items * Sorted by call time * Filtered by All PCRs - PCR status </span> */}
          <div style={{ display: "flex", justifyContent: "end" }}>
            {/* <div style={{display:'hidden'}}>Synced just a moment ago.
              </div> */}
            <div className="slds-form slds-form_inline">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div className="slds-form-element">
                  <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left">
                    <span className="slds-input__icon slds-input__icon_left">
                      <AiOutlineSearch />
                    </span>
                    <input
                      type="text"
                      className="slds-input"
                      placeholder="Search this list..."
                    />
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid",
                    display: "flex",
                    paddingBottom: "2px",
                    alignItems: "center",
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    paddingTop: "2px",
                    borderRadius: "4px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "black",
                    }}
                  >
                    <IoMdRefresh />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table
          className="slds-table slds-table_cell-buffer slds-table_bordered slds-table_col-bordered"
          aria-label="Example table of Opportunities with vertical borders
        "
          style={{
            border: "1px solid grey",
          }}
        >
          <thead style={{ fontSize: "14px" }}>
            <tr className="slds-line-height_reset">
              <th className="" scope="col">
                <div
                  className="slds-truncate"
                  title="Opportunity Name"
                  style={{ textAlign: "center" }}
                >
                  ID#
                </div>
              </th>
              <th className="" scope="col">
                <div
                  className="slds-truncate"
                  title="Opportunity Name"
                  style={{ textAlign: "center" }}
                >
                  Transfer Number
                </div>
              </th>
              <th className="" scope="col">
                <div
                  className="slds-truncate"
                  title="Opportunity Name"
                  style={{ textAlign: "center" }}
                >
                  Vehicle Number
                </div>
              </th>{" "}
              <th className="" scope="col">
                <div
                  className="slds-truncate"
                  title="Opportunity Name"
                  style={{ textAlign: "center" }}
                >
                  Vehicle Type
                </div>
              </th>{" "}
              <th className="" scope="col">
                <div
                  className="slds-truncate"
                  title="Opportunity Name"
                  style={{ textAlign: "center" }}
                >
                  Your Role
                </div>
              </th>{" "}
              <th className="" scope="col">
                <div
                  className="slds-truncate"
                  title="Opportunity Name"
                  style={{ textAlign: "center" }}
                >
                  Attended Practioner
                </div>
              </th>
              {/* <th className="" scope="col">
              <div
                className="slds-truncate"
                title="Opportunity Name"
                style={{ textAlign: "center" }}
              >
                Age
              </div>
            </th>{" "} */}
              {/* <th className="" scope="col">
              <div
                className="slds-truncate"
                title="Opportunity Name"
                style={{ textAlign: "center" }}
              >
                Call Time
              </div>
            </th> */}
              <th className="" scope="col" style={{ textAlign: "center" }}>
                <div className="slds-truncate" title="Opportunity Name">
                  PCR Status
                </div>
              </th>
              {/* Add more header columns as needed */}
            </tr>
          </thead>
          <tbody>
            {( pcrdata?.data==null) ? (
              <tr>
                <td
                  colSpan="7"
                  style={{ textAlign: "center", fontSize: "20px" }}
                >
                  There are no records in the List
                </td>
              </tr>
            ) : (
            
              pcrdata?.data?.map((pcr, index) => (
                <tr
                  key={index}
                  className="slds-hint-parent "
                  style={{ fontSize: "16px" }}
                >
                  <td data-label="Number">
                    <div
                      className="slds-truncate"
                      title={pcr.Number}
                      style={{ textAlign: "center" }}
                    >
                      {pcr.PartnerId}
                    </div>
                  </td>
                  <td data-label="Transfer Number">
                    <div
                      className="slds-truncate"
                      title={pcr.transferNumber}
                      style={{ textAlign: "center" }}
                    >
                      {pcr.TransferNumber}
                    </div>
                  </td>
                  <td data-label="PCR Vehicle Number">
                    <div
                      className="slds-truncate"
                      title={pcr.pcrFormNumber}
                      style={{ textAlign: "center" }}
                    >
                      <a
                        href="#"
                        tabIndex="-1"
                        onClick={() => setSelect(pcr.TransferNumber)}
                      >
                        {pcr.VehicleNumber}
                      </a>
                    </div>
                  </td>
                  <td data-label="PCR Vehicle Type">
                    <div
                      className="slds-truncate"
                      title={pcr.pcrFormNumber}
                      style={{ textAlign: "center" }}
                    >
                      {pcr.VehicleType}
                    </div>
                  </td>
                  <td data-label="PCR Form Roll">
                    <div
                      className="slds-truncate"
                      title={pcr.pcrFormNumber}
                      style={{ textAlign: "center" }}
                    >
                      {pcr.Role}
                    </div>
                  </td>
                  <td data-label="PCR Form Attended Practioner">
                    <div
                      className="slds-truncate"
                      title={pcr.pcrFormNumber}
                      style={{ textAlign: "center" }}
                    >
                      {pcr.Practitioner}
                    </div>
                  </td>
                  {/* <td data-label="PCR Form Number">
                  <div
                    className="slds-truncate"
                    title={pcr.pcrFormNumber}
                    style={{ textAlign: "center" }}
                  >
                    M
                  </div>
                </td> */}
                  {/* <td data-label="PCR Form Number">
                  <div
                    className="slds-truncate"
                    title={pcr.pcrFormNumber}
                    style={{ textAlign: "center" }}
                  >
                    49
                  </div>
                </td> */}
                  {/* <td
                  data-label="PCR Form Number"
                  style={{ textAlign: "center" }}
                >
                  <div className="slds-truncate" title={pcr.callTime}>
                    07:05
                  </div>
                </td> */}
                  <td
                    data-label="PCR Form Number"
                    style={{ textAlign: "center", backgroundColor: "pink" }}
                  >
                    <div className="slds-truncate" title={pcr.pcrStatus}>
                      NEW
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {selectedData && (
        <>
          <div
            style={{
              backgroundColor: "white",
              border: "1px solid gray",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", backgroundColor: "white" }}>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <span style={{ fontSize: "28px", color: "red" }}>
                  <PiFirstAidKitFill />
                </span>
                <p style={{ fontSize: "14px" }}>ID#</p>
                <p style={{ fontSize: "14px" }}>{selectedData.PartnerId}</p>
              </div>
              <div></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "7px",
                justifyContent: "space-between",
                backgroundColor: "#F3F3F3",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "28px", color: "blue" }}>
                  <AiFillCar />
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "14px",
                  }}
                >
                  <div>
                    <p> Transfer Number</p>
                  </div>
                  <p>{selectedData?.TransferNumber}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "14px",
                  }}
                >
                  <div>
                    <p> Vehicle Number</p>
                  </div>
                  <p>{selectedData.VehicleNumber}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "14px",
                  }}
                >
                  <div>
                    <p> Vehicle type</p>
                  </div>
                  <p>{selectedData.VehicleType}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "24px",
                }}
              >
                {/* <span style={{ color: "grey" }}>
                <FiEdit2 />
              </span> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "28px",
                padding: "10px",
                fontSize: "14px",
                backgroundColor: "",
              }}
            >
              <div>
                <h1>Attending Parctioner</h1>
                <h1>{selectedData.Practitioner}</h1>
              </div>
              {/* <div>
                <h1>Supporting Practioner </h1>
                <h1>Danile Logan (PCP)</h1>
              </div> */}
            </div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#F3f3F3",
                gap: "20px",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",

                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "28px", color: "purple" }}>
                  <MdContacts />
                </span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ fontSize: "14px" }}>Patient Account</p>
                  <p style={{ fontSize: "14px" }}>
                    {selectedData?.patientName}
                  </p>
                </div>
              </div>

              <div></div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "28px",
                padding: "10px",
                fontSize: "14px",
                backgroundColor: "",
              }}
            >
              <div>
                <h1>Your Role</h1>
                <h1>{selectedData.Role}</h1>
              </div>
              {/* <div>
                <h1>Age</h1>
                <h1>63 years 3months</h1>
              </div> */}
              <div>
                <h1>PCR Status</h1>
                <h1>{"New"}</h1>
              </div>
              {/* <div>
                <h1>Identifier</h1>
                <h1>100200390(Medicare)</h1>
              </div>
              <div>
                <h1>Primary Address</h1>
                <h1>38 Canal City lahore</h1>
              </div>
              <div>
                <h1>Primary Phone Number</h1>
                <h1>506-645-6568</h1>
              </div> */}
            </div>
            <div class="slds-text-align_right ">
              <button
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  backgroundColor: "#F0F0F0",
                  border: "2px solid grey",
                  borderRadius: "5px",
                  color: "#777777",
                  marginRight: "50px",
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </>
      )}
      {/* <div class="slds-text-align_right ">
         
         <button
           style={{
             fontSize: "20px",
             fontWeight: "500",
             marginTop:'5px',
             backgroundColor: "#F0F0F0",
             border: "2px solid grey",
             borderRadius:'5px',
             color: "#777777",
             marginRight: "50px",
         
          
           }}
         >
           SUBMIT
         </button>
     
     </div> */}
    </div>
  );
};

export default MyPCRsPage;
