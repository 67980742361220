export function register() {
    if ('serviceWorker' in navigator) {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
      window.addEventListener('load', () => {
        navigator.serviceWorker.register(swUrl)
          .then((registration) => {
            console.log('Service worker registered:', registration);
          })
          .catch((error) => {
            console.error('Error during service worker registration:', error);
          });
      });
    }
  }
  