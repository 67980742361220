import Dexie from "dexie";
import { useDataContext } from "../Context/DataContext";

// Create a Dexie database instance and open it
const db = new Dexie("poc");
db.version(1).stores({
  mypcr: "++id",
  InstallStatus: "++id",
});

// Ensure the database is open before performing operations
db.open()
  .then(() => {
    // Database is open and ready for use
  })
  .catch((error) => {
    console.error("Error opening the database: ", error);
  });

export const AddFormDataToMypcr = async (formData) => {
 const {data,setData}=useDataContext();
  try {
    // Ensure the database is open before adding data
    if (!db.isOpen()) {
      throw new Error("Database is not open.");
    }
    console.log("saving data to db ");

    await db.mypcr.add(formData);
    const updatedData = { ...data, ...formData };
    console.log('form',{formData});
    //  setData(updatedData);

    // setData(formData);

    return true; // Success
  } catch (error) {
    console.error("Error adding data to 'mypcr' object store: ", error);
    return false; // Error
  }
};
export const RetrieveFormDataFromMypcr = async () => {
  
  
    console.log("entering modes");
    
    const data2 = await db.mypcr.toArray();
  
    console.log("located", data2);
  
  
  
    console.log("data reterive", data2);
    
    return data2;
  };

export const addInstallStatus = async (newStatus) => {
  console.log('values is ')
  try {
    console.log('entering install status')
    await db.InstallStatus.add({ status: newStatus });
    return true; // Success
  } catch (error) {
    console.error(
      "Error adding install status to 'InstallStatus' object store: ",
      error
    );
    return false; // Error
  }
};
export const getInstallStatus = async () => {
  try {
    console.log('coming in it')
    const installStatus = await db.InstallStatus.toArray();
    return installStatus; // Array of installation status objects
  } catch (error) {
    console.error(
      "Error retrieving install status from 'InstallStatus' object store: ",
      error
    );
    return null; // Error
  }
};
