import React from "react";
import { useState,useEffect } from "react";
import Tab1 from "../components/Dashboard/Tab1";
import Footer from "../components/Footer";
import { Link, redirect } from "react-router-dom";
import MyPCRsPage from "./MyPCRsPage";
import { MdContacts } from "react-icons/md";
import OnlineStatus from "../components/Dashboard/Online";
import { useTabContext } from "../Context/TabContext";
import { getInstallStatus } from "../DB/db2";
const DashboardPage = () => {
  const { active,Tabon } = useTabContext();
  const value=localStorage.getItem('isAuthenticated');
  async function fetchData() {
    console.log('entering das')
    const installationStatusData = await getInstallStatus();
    if (installationStatusData !== null) {
      // Use the installationStatusData array in your component
      console.log("your",installationStatusData[0]?.status);
    } else {
      // Handle the error
      console.log('error coming')
    }
  }



 
  const userIsOnline = OnlineStatus();
  const [isOnline, setIsOnline] = useState(true);
 console.log('active',active)
  const [activeTab, setActiveTab] = useState(active);
  
  // Set the initial active tab index
 
  const handleTabClick = (index) => {
   
    fetchData();
    setActiveTab(index);
  };
  return (
    <div style={{ display: "flex ", height: "110vh" }}>
      <div
        style={{
          backgroundColor: "white",
          marginTop: "10px",
          padding: "10px",
          marginLeft: "20px",
          marginRight: "20px",
          width: "100%",
        }}
      >
        {userIsOnline?(
         <div class="slds-text-align_right ">
          <Link to="/login">
            <button
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#777777",
                marginRight: "50px",
                backgroundColor: "transparent ",
                border: "none",
              }}
            >
              Logout
            </button>
          </Link>
        </div> 
        ):(null)}
        {/* <div class="slds-text-align_right ">
          <Link to="/salesforcecallback">
            <button
              style={{
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "20px",
                padding: "5px",
                color: "#777777",
                marginRight: "32px",
                borderRadius: "5px",
                backgroundColor: "#F0F0F0",
                border: "2px solid grey",
              }}
            >
              call details
            </button>
          </Link>
        </div>
​ */}
        <div class="slds-context-bar">
          <div class="slds-context-bar__primary">
            <div className="slds-tabs_default ">
              <ul
                className="slds-tabs_default__nav"
                role="tablist"
                style={{ gap: "80px" }}
              >
                <h1 style={{ paddingTop: "5px", fontSize: "20px" }}>
                  CommunityCareNB
                </h1>
                <li
                  className={`slds-tabs_default__item slds-rounded custom-border-radius ${
                    activeTab === 2 ? "slds-is-active" : ""
                  }`}
                  style={{ fontSize: "15px" }}
                  title="Item Two"
                  role="presentation"
                  onClick={() => handleTabClick(2)}
                >
                  <p
                    className="slds-tabs_default__link"
                    role="tab"
                    tabIndex="-1"
                    aria-selected={activeTab === 2 ? "true" : "false"}
                    aria-controls="tab-default-2"
                    id="tab-default-2__item"
                  >
                    My PCRs
                  </p>
                </li>

                <li
                  className={`slds-tabs_default__item slds-rounded custom-border-radius ${
                    activeTab === 3 ? "slds-is-active" : ""
                  }`}
                  title="Item Three"
                  style={{ fontSize: "15px" }}
                  role="presentation"
                  onClick={() => handleTabClick(3)}
                >
                  <p
                    className="slds-tabs_default__link"
                    role="tab"
                    tabIndex="-1"
                    aria-selected={activeTab === 3 ? "true" : "false"}
                    aria-controls="tab-default-3"
                    id="tab-default-3__item"
                  >
                    Dashboard
                  </p>
                </li>
                {/* <li
                  className={`slds-tabs_default__item slds-rounded custom-border-radius ${
                    activeTab === 1 ? "slds-is-active" : ""
                  }`}
                  title="Item Three"
                  style={{ fontSize: "15px" }}
                  role="presentation"
                  onClick={() => handleTabClick(1)}
                >
                  <a
                    className="slds-tabs_default__link"
                    role="tab"
                    href='/salesforcecallback'
                    tabIndex="-1"
                    aria-selected={activeTab === 3 ? "true" : "false"}
                    aria-controls="tab-default-3"
                    id="tab-default-3__item"
                  >
                    Call details
                  </a>
                </li> */}
                <div></div>
              </ul>

              <div
                id="tab-default-1"
                className={`slds-tabs_default__content ${
                  activeTab === 1 ? "slds-show" : "slds-hide"
                }`}
                role="tabpanel"
                aria-labelledby="tab-default-1__item"
              ></div>
              <div
                id="tab-default-2"
                className={`slds-tabs_default__content ${
                  activeTab === 2 ? "slds-show" : "slds-hide"
                }`}
                role="tabpanel"
                aria-labelledby="tab-default-2__item"
              >
                <div class="" style={{ backgroundColor: "white" }}>
                  <MyPCRsPage/>
                </div>
              </div>
              <div
                id="tab-default-3"
                className={`slds-tabs_default__content ${
                  activeTab === 3 ? "slds-show" : "slds-hide"
                }`}
                role="tabpanel"
                aria-labelledby="tab-default-3__item"
              >
                <div class="" style={{ backgroundColor: "white" }}>
                  <Tab1 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;