import React from "react";
import { Link } from "react-router-dom";
import { MdContacts } from "react-icons/md";
const Tab1 = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <span
            style={{
              alignItems: "center",
              display: "flex",
              fontSize: "24px",
              color: "green",
            }}
          >
            <MdContacts />
          </span>

          <p style={{ fontSize: "18px", color: "grey" }}>PCR (offline Mode)</p>
        </div>

        <div>
          <button
            style={{
              paddingLeft: "30px",
              paddingTop: "10px",
              fontSize: "16px",
              paddingBottom: "10px",
              paddingRight: "30px",
              borderRadius: "5px",
            }}
          >
            <Link
              to="/createecpr"
              style={{ textDecoration: "none", color: "grey" }}
            >
              Create New PCR
            </Link>
          </button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          padding: "15px",
          fontSize: "15px",
          color: "grey",
        }}
      >
        <p>PCR is curretly not available Connection lost 11 minutes ago </p>
      </div>
    </div>
  );
};

export default Tab1;
