import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateInstallationStatus } from "../DB/db";
import InstallButton from "./InstallPage";
import { addInstallStatus } from "../DB/db2";
import { getInstallStatus } from "../DB/db2";
import OnlineStatus from "../components/Dashboard/Online";
function LoginPage() {

  const isonline=OnlineStatus();
  const [installationStatus, setInstallationStatus] = useState(null);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const HandleLogin = () => {
    
    localStorage.setItem('isAuthenticated',1);
    console.log('value sent')
    addInstallStatus(1);
  
    // window.addEventListener('popstate', function(event) {
    //   // The event object contains information about the previous state and URL.
    //   var previousURL = event.state ? event.state.url : null;
    //   console.log('var',previousURL);
    //   alert(previousURL);
    //   // Do something with the previousURL, e.g., log it or use it in your application.
    // });
   
  
    // Redirect to OAuth provider for authentication
    {
      installationStatus !== 1 
        ? navigate("/salesforcecallback")
        : navigate("/dashboard");
    }
  };
  // useEffect(() => {
  //   updateInstallationStatus()
  //     .then((status) => {
  //       if (status !== null) {
  //         setInstallationStatus(1);
  //         console.log("Installation status:", status);
  //         // You can store the status in your component's state or context if needed.
  //       } else {
  //         console.log("Installation status not found.", status);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error retrieving installation status:", error);
  //     });
  // }, []);

  return (
    <div>
      <div
        className="slds-grid slds-grid_vertical-align-center slds-align_absolute-center"
        style={{ height: "100vh" }}
      >
        <div
          className="slds-box slds-theme_default slds-p-around_medium"
          style={{
            maxWidth: "400px",
            width: "100%",
            backgroundColor: "transparent",
          }}
        >
          {/* <h2
            className="slds-text-heading_medium slds-m-bottom_medium"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              fontWeight: 600,
              fontSize: 26,
            }}
          >
            Login
          </h2> */}
          <form>
            {/* <div className="slds-form-element slds-m-bottom_medium">
              <div className="slds-form-element__control">
                <input
                  type="text"
                  id="username"
                  className="slds-input"
                  placeholder="Enter your username"
                />
              </div>
            </div> */}

            <div
              className="slds-m-top_medium"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="slds-button slds-button_brand"
                type="submit"
                onClick={HandleLogin}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* {error && <p>Error: {error}</p>}
      <button onClick={handleLogin}>Login with Salesforce</button> */}
    </div>
  );
}

export default LoginPage;
