import { useEffect } from "react";
import { useDataContext } from "../Context/DataContext";
import { v4 as uuidv4 } from "uuid";

export const updateInstallationStatus = (newStatus) => {
  const dbVersion = 10;
  const dbName = "poc";
  const statusTable = "InstallStatus";
  const statusRequest = indexedDB.open(dbName, dbVersion);
  var openDB;
  openDB = () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(dbName, dbVersion);

      request.onerror = (event) => {
        console.log("Error opening db", event.target.error);
        reject(event.target.error);
      };

      request.onsuccess = (event) => {
        console.log("opening db ");
        const db = event.target.result;
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;

        // Create a new object store (table)

        if (!db.objectStoreNames.contains(statusTable)) {
          const statusObjectStore = db.createObjectStore(statusTable, {
            keyPath: "id",
          });
        }
      };
    });
  };
  return new Promise((resolve, reject) => {
    statusRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(statusTable, "readwrite");
      const statusStore = transaction.objectStore(statusTable);

      // Update the status in the object store
      const updateRequest = statusStore.put({ id: 1, status: newStatus });

      updateRequest.onsuccess = () => {
        resolve(newStatus);
      };

      updateRequest.onerror = (event) => {
        reject(event.target.error);
      };
    };

    statusRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
};
export const getInstallationStatus = () => {
  const dbVersion = 10;
  const dbName = "poc";
  const statusTable = "InstallStatus";
  const statusRequest = indexedDB.open(dbName, dbVersion);

  return new Promise((resolve, reject) => {
    statusRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(statusTable, "readonly");
      const statusStore = transaction.objectStore(statusTable);

      const getRequest = statusStore.get(1); // Assuming the ID is 1

      getRequest.onsuccess = () => {
        if (getRequest.result) {
          resolve(getRequest.result.status);
        } else {
          resolve(null); // No status found for the given ID
        }
      };

      getRequest.onerror = (event) => {
        reject(event.target.error);
      };
    };

    statusRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const IndexedDBHandler = ({ formData }) => {
  console.log("formData", formData);
  const { setData } = useDataContext();
  const dbName = "poc";
  const tableName = "mypcr";
  const statusTable = "InstallStatus";
  const dbVersion = 10;
  useEffect(() => {
    if (formData) {
      console.log("insidedb", formData);
      const openDB = () => {
        return new Promise((resolve, reject) => {
          const request = window.indexedDB.open(dbName, dbVersion);

          request.onerror = (event) => {
            console.log("Error opening db", event.target.error);
            reject(event.target.error);
          };

          request.onsuccess = (event) => {
            console.log("opening db ");
            const db = event.target.result;
            resolve(db);
          };

          request.onupgradeneeded = (event) => {
            const db = event.target.result;

            // Create a new object store (table)
            if (!db.objectStoreNames.contains(tableName)) {
              const objectStore = db.createObjectStore(tableName, {
                autoIncrement: true,
                keyPath: "id",
              });
            }
            if (!db.objectStoreNames.contains(statusTable)) {
              const statusObjectStore = db.createObjectStore(statusTable, {
                keyPath: "id",
              });
            }
          };
        });
      };

      const insertFormDataToIndexedDB = (db, formData) => {
        console.log("insert data to db");

        const transaction = db.transaction(tableName, "readwrite");

        const objectStore = transaction.objectStore(tableName);
        console.log("Data to be added:", formData);

        // Extract the relevant data from the formData object and store it as a new record in the object store
        const {
          TransferNumber,
          VehicleNumber,
          VehicleType,
          Role,
          PartnerId,
          Practitioner,
        } = formData;
        const data = {
          id: uuidv4(),
          TransferNumber,
          VehicleNumber,
          VehicleType,
          Role,
          PartnerId,
          Practitioner,
        };
        console.log("data", data);
        const addRequest = objectStore.add(data);
        //   const { TransferNumber } = formData;
        //   const data = {
        //     TransferNumber,
        //   };
        //   objectStore.add(data);
        addRequest.onsuccess = () => {
          console.log("form data sucess");
          const getRequest = objectStore.getAll();

          getRequest.onsuccess = (event) => {
            setData(event.target.result);
          };
          getRequest.onerror = (event) => {
            console.log("error is");
            console.error(
              "Error fetching data from IndexedDB: ",
              event.target.error
            );
          };
        };

        transaction.oncomplete = () => {
          console.log("Form data inserted into IndexedDB successfully!");
        };

        transaction.onerror = (event) => {
          console.error(
            "Error inserting data into IndexedDB: ",
            event.target.error
          );
        };
      };

      openDB()
        .then((db) => {
          insertFormDataToIndexedDB(db, formData);
        })
        .catch((error) => {
          console.error("Error opening IndexedDB: ", error);
        });
    }
  }, [formData]);
}; // The component doesn't render anything

export default IndexedDBHandler;
