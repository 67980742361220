// AuthContext.js

import React, { createContext, useContext, useState } from 'react';

const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [active, setActiveTabbar] = useState(3);

  const Tabon = () => {
    // Perform your authentication logic here, and if successful, set isAuthenticated to true
    setActiveTabbar(2);
    console.log('active tab',active);
  };

 

  return (
    <TabContext.Provider value={{ active,Tabon,setActiveTabbar  }}>
      {children}
    </TabContext.Provider>
  );
};

export const useTabContext = () => {
  return useContext(TabContext);
};
