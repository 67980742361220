// Create a context to manage the deferred prompt
import { createContext, useContext, useState, useEffect } from 'react';

const DeferredPromptContext = createContext();

export const useDeferredPrompt = () => useContext(DeferredPromptContext);

export const DeferredPromptProvider = ({ children }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  
 
  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    };
  }, []);

  return (
    <DeferredPromptContext.Provider value={deferredPrompt}>
      {children}
    </DeferredPromptContext.Provider>
  );
};
