import React, { useState, useEffect } from "react";
import IndexedDB2Initializer from "../DB/Initlize";
import { getInstallStatus } from "../DB/db2";
import { useNavigate } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { updateInstallationStatus } from "../DB/db";
import { getInstallationStatus } from "../DB/db";
import { Link } from "react-router-dom";
import { addInstallStatus } from "../DB/db2";
import { AuthProvider } from "../Context/AuthContext";
import { useAuth } from "../Context/AuthContext";
import { useDeferredPrompt } from "../Context/PromptContext";
import OnlineStatus from "../components/Dashboard/Online";
const InstallPWA = () => {
  const { install, newValue } = useAuth();
  const [newStatus, setnewStatus] = useState(false);
  const userIsOnline = OnlineStatus();
  const [canInstall, setCanInstall] = useState(false);
  const deferredPrompts = useDeferredPrompt();
  const { isAuthenticated, Auth, notAuth } = useAuth();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  console.log("is", isAuthenticated);

  const redirect = localStorage.getItem("isAuthenticated");
  console.log("redirect", redirect);
  window.addEventListener('offline', (event) => {
    // PWA has been installed
    // You can set your `installStatus` to 1 here or perform any other actions.
 
    navigate('/dashboard')
  });
  
  window.addEventListener('appinstalled', (event) => {
    // PWA has been installed
    // You can set your `installStatus` to 1 here or perform any other actions.
    addInstallStatus(1)
    navigate('/login')
  });
  
// Listen for browser install event
useEffect(() => {
  const onInstall = () => {
    deferredPrompt.prompt();
    console.log('enterung use')
    // Navigate on accept
    deferredPrompt.userChoice
      .then(choice => {
        if(choice.outcome === 'accepted') {
          console.log('enterung use')

          navigate('/login'); 
        }
      });
  };
  
  window.addEventListener('appinstall', onInstall);

  return () => window.removeEventListener('appinstall', onInstall);

}, []);

// Remove click handler from link 





  
  useEffect(() => {
    // ...

    // Automatically trigger the installation prompt when online and not authenticated
    if (isOnline && !isAuthenticated) {
      if (deferredPrompts) {
        console.log("entering 2");
        deferredPrompts.prompt();
        deferredPrompts.userChoice.then((choiceResult) => {
          console.log('entering prompt')
          if (choiceResult.outcome === "accepted") {
            addInstallStatus(1);
            console.log("User accepted the installation");
            setCanInstall(true);
            // localStorage.setItem('isAuthenticated',true)
  
            // IndexedDB2Initializer();
            // updateInstallationStatus(1);
            navigate("/Login");
          } else {
            console.log("User declined the installation");
          }
          setDeferredPrompt(null);
        });
      }
    }
  }, [isAuthenticated, isOnline]);

  
  useEffect(() => {
   
    if (redirect || !isOnline) {
      console.log("checking status");
      navigate("/Dashboard");
    }
    // else if (redirect){
    //     navigate('/Dashboard');
    // }
    else if (!isAuthenticated) {
      // If not authenticated, run the login process within the useEffect
      // This sets isAuthenticated to true
      navigate("/azcallback");
    } else {
      console.log("Already authenticated");
    }
  }, [isAuthenticated, Auth]);
  useEffect(() => {
    async function fetchData() {
      const installationStatusData = await getInstallStatus();
      if (installationStatusData !== null) {
        var variable = installationStatusData[0]?.status;
        // Use the installationStatusData array in your component
        setnewStatus(variable);
        install();
      } else {
        // Handle the error
        console.log("error coming");
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // Event listener to capture the beforeinstallprompt event
    if (isAuthenticated) {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        console.log("enter");
        setDeferredPrompt(e);
      });
    }
  }, []);

  const [val, setVal] = useState(0);
  var value;
  const dbName = "poc";
  const objectStoreName = "InstallStatus";
  // async function doesObjectStoreExist() {
  //   if (!window.indexedDB) {
  //     console.log("IndexedDB is not supported in this browser");
  //     return false;
  //   }

  //   return new Promise((resolve) => {
  //     const request = indexedDB.open(dbName);

  //     request.onsuccess = function (event) {
  //       const db = event.target.result;

  //       if (db.objectStoreNames.contains(objectStoreName)) {
  //         resolve(true);
  //         setCanInstall(true);
  //       } else {
  //         resolve(false);
  //         setVal(0);
  //       }

  //       db.close();
  //     };

  //     request.onerror = function () {
  //       resolve(false);
  //     };
  //   });
  // }

  const navigate = useNavigate();
  const [deferredPrompt, setDeferredPrompt] = useState(null);


  localStorage.setItem("auth", true);

  const authen = localStorage.getItem("auth");
  console.log("authen", authen);

  const Handledb = () => {
    IndexedDB2Initializer();
  };
  useEffect(() => {
    // Event listener to capture the beforeinstallprompt event

    console.log("asking");

    // Event listener to check online/offline status
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    // Cleanup the event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  const handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    setDeferredPrompt(e);
  };

  const handleOnlineStatus = () => {
    setIsOnline(navigator.onLine);
  };

  const handleInstallClick = () => {
  
    console.log("en", deferredPrompt);
    if (deferredPrompts) {
      console.log("entering 2");
      deferredPrompts.prompt();
      deferredPrompts.userChoice.then((choiceResult) => {
        console.log('entering prompt')
        if (choiceResult.outcome === "accepted") {
          addInstallStatus(1);
          console.log("User accepted the installation");
          setCanInstall(true);
          // localStorage.setItem('isAuthenticated',true)

          // IndexedDB2Initializer();
          // updateInstallationStatus(1);
          navigate("/Login");
        } else {
          console.log("User declined the installation");
        }
        setDeferredPrompt(null);
      });
    } else {
      // Fallback to the getInstalledRelatedApps method
      if (navigator.getInstalledRelatedApps) {
    
        navigator.getInstalledRelatedApps().then((relatedApps) => {
          if (relatedApps.length > 0) {
            console.log("PWA is already installed");
        
          } else {
            console.log("PWA is not installed");
          }
        });
      }
    }
  };
  let installEvent;

window.addEventListener('beforeinstallprompt', e => {
  // Prevent prompt
  e.preventDefault();
  
  // Save event for later use
  installEvent = e;
});
console.log("install evenr",installEvent)
 console.log('DEFF',deferredPrompt)
  return (
    <>
      {newStatus ? (
        navigate("/Login")
      ) : (
        <a
          onClick={handleInstallClick}
          style={{ display: isOnline ? "block" : "none" }}
        >
          Install App
        </a>
      )}
    </>
  );
};

export default InstallPWA;
