import React, { createContext, useContext, useState, useEffect } from "react";

const DataContext = createContext();

export function useDataContext() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {

  const storedData = localStorage.getItem("storage");
 
  const [data, setData] = useState(storedData ? JSON.parse(storedData) : []);
  

  // Store data in local storage whenever it changes
  useEffect(() => {
    console.log('data is ',data)
    localStorage.setItem("storage", JSON.stringify(data));
  }, [data]);


  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
}
